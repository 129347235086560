import React, { useEffect } from "react";
import upiQr from "assets/img/payment-method/upi-qrcode.PNG";

export default function Local({ setIsFirstStepValid }) {
  
  useEffect(() => {
    setIsFirstStepValid(false);
  }, []);

  return (
    <div className="my-3">
      <div className="d-flex justify-content-center">
        <img src={upiQr} alt="UPI QR" style={{
          maxHeight: "20%",
          maxWidth: "20%",
          cursor: "pointer",
        }} onClick={()=> {
          window.open("https://wa.link/3me3md", "_blank");
        }} />
      </div>
    </div>
  );
}
