import { cpUrl } from "content";

export default [
  {
    type: "Register Live",
    url: `${cpUrl}register/forex/live`,
  },
  {
    type: "Register Demo",
    url: `${cpUrl}register/forex/demo`,
  },
  {
    type: "Register Sub IB",
    url: `${cpUrl}register/forex/ib`,
  },
];
