import React, { useEffect } from "react";
import upiQr from "assets/img/payment-method/upiii.png";
import { Label } from "reactstrap";

export default function UPI({
  t, setIsFirstStepValid, setPaymentPayload,
}) {
  const upiId = "reachhks1@paytm";
  const [customerId, setCustomerId] = React.useState(null);

  useEffect(() => {
    if (customerId) {
      if (customerId.length > 3){
        setIsFirstStepValid(true);
        setPaymentPayload({
          upiId: customerId,
        });
      }
    } else {
      setIsFirstStepValid(false);
    }
  }, [customerId]);

  return (
    <div className="my-3">
      <div className="d-flex justify-content-center">
        <img src={upiQr} alt="UPI QR" style={{
          maxHeight: "20%",
          maxWidth: "20%",
        }} />
      </div>
      <div>
        <div className="d-flex flex-column my-2">
          <div className="d-flex justify-content-center">
            <h5 className="mb-4">{t("UPI")} : {" "}</h5>
            <h5>{` ${upiId}`}</h5>
          </div>
        </div>
        <div>
          <Label>{t("UPI ID")}</Label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter UPI ID"
            onChange={(e) => {
              setCustomerId(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
